var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('heads'),_c('div',{staticClass:"cen",style:({
      backgroundImage: 'url(' + require('@/assets/' + _vm.backgroud) + ')',
    })},[_c('div',{staticClass:"cen_box"},[_vm._l((_vm.courses),function(item,index){return _c('div',{key:index},[(item.listorder == 1)?_c('div',{staticClass:"cen_top cen_list"},_vm._l((item.practice_course),function(items,indexs){return _c('router-link',{key:indexs,attrs:{"to":{
              path: '/practice/guoxue',
              query: { id: items.id },
            }}},[_vm._v(" "+_vm._s(items.name)+" ")])}),1):_vm._e(),(item.listorder == 2)?_c('div',{staticClass:"cen_center cen_list"},_vm._l((item.practice_course),function(items,indexs){return _c('router-link',{key:indexs,attrs:{"to":{
              path: '/practice/nationCulture',
              query: { id: items.id },
            }}},[_vm._v(" "+_vm._s(items.name)+" ")])}),1):_vm._e(),(item.listorder == 3)?_c('div',{staticClass:"cen_bottom cen_list"},_vm._l((item.practice_course),function(items,indexs){return _c('router-link',{key:indexs,attrs:{"to":{
              path: '/practice/history',
              query: { id: items.id },
            }}},[_vm._v(" "+_vm._s(items.name)+" ")])}),1):_vm._e()])}),_c('div',[_c('div',{staticClass:"cen_medicine cen_list"},_vm._l((_vm.gradeCategorys),function(item,index){return _c('router-link',{key:index,attrs:{"to":{
              path: '/practice/medicine',
              query: { id: item.id },
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])],2)]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }